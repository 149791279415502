<template>
<svg viewBox="0 0 222 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.57536 13.454C9.57536 10.2435 12.8886 8.93555 15.8545 8.93555C18.396 8.93555 20.987 10.0095 22.6381 11.4414C22.7759 11.5598 22.9799 11.5373 23.0819 11.3879L26.7866 5.97585C26.8803 5.83773 26.85 5.64324 26.7149 5.54458C22.9606 2.77092 19.1981 1.80127 15.1571 1.80127C8.41214 1.80127 1.72778 6.08296 1.72778 13.5132C1.72778 18.2093 4.28576 21.0618 7.48322 22.9645C12.9465 26.2343 20.5046 26.8882 20.5046 32.2382C20.5046 35.2684 18.0624 37.4698 14.5755 37.4698C10.9674 37.4698 7.52457 35.6264 5.19814 33.1289C5.07961 33.0021 4.8839 32.9993 4.76262 33.1261L0.0849442 38.1294C-0.0335826 38.2563 -0.0280697 38.4592 0.101483 38.5776C4.24165 42.3717 9.21427 44.6041 14.5783 44.6041C21.9021 44.6041 28.355 39.4909 28.355 32.1198C28.3522 18.0909 9.57536 20.1711 9.57536 13.454Z" fill="white"/>
    <path d="M48.5677 38.0594C48.4216 37.7268 48.0881 37.5267 47.7353 37.5746C47.0627 37.6676 46.1393 37.7522 45.3758 37.7522C43.5152 37.7522 42.2941 37.2166 41.5388 36.3259C40.8993 35.5535 40.6099 34.4824 40.6099 32.701V21.7078H46.6107C46.9332 21.7078 47.2198 21.5105 47.3549 21.2089C48.2094 19.2724 49.345 17.4966 50.7095 15.9293C50.9686 15.6334 50.7646 15.1683 50.3787 15.1683H40.6099V9.04594C40.6099 8.51601 40.0944 8.14957 39.6093 8.32997L34.5209 10.2326C33.6389 10.5624 33.0518 11.4193 33.0518 12.3805V15.9829V22.5224V31.8046C33.0518 35.9059 33.6912 38.4625 35.1439 40.4244C37.0624 43.0402 40.3756 44.5877 44.9099 44.5877C46.3653 44.5877 47.7491 44.3847 49.6179 43.9929C50.2602 43.8576 50.8556 43.7308 51.3848 43.5955C51.6549 43.525 51.7514 43.1924 51.5722 42.9753C50.3594 41.4955 49.345 39.8437 48.5677 38.0594Z" fill="white"/>
    <path d="M133.998 9.79853C136.162 9.44618 137.871 7.61117 138.056 5.37871C138.329 2.07231 135.531 -0.461755 132.267 0.0709906C130.101 0.423336 128.392 2.25553 128.207 4.49081C127.934 7.79439 130.735 10.3313 133.998 9.79853Z" fill="white"/>
    <path d="M159.054 15.1827H157.847C153.02 15.1827 149.591 16.967 147.265 19.8195C145.52 21.9392 144.58 24.6058 144.47 29.0002H144.459V30.9762C144.414 33.5779 144.423 35.3537 144.282 36.904C144.197 37.8709 142.049 37.6905 140.029 37.3889C139.012 37.2366 137.559 36.7716 136.407 36.3205H136.396V35.2663V15.9212C136.396 15.5012 136.062 15.1601 135.652 15.1601H129.585C129.174 15.1601 128.841 15.5012 128.841 15.9212V36.3234C123.049 37.9864 120.235 37.7497 120.235 37.7497C118.374 37.7497 117.153 37.2141 116.398 36.3234C115.759 35.551 115.469 34.4799 115.469 32.6984V7.19713V2.94081C115.469 2.41088 114.954 2.04162 114.466 2.22484L109.375 4.13596C108.495 4.46576 107.908 5.32266 107.908 6.28104V7.19713V31.8049C107.908 35.9062 108.548 38.4628 110 40.4247C111.919 43.0405 115.232 44.588 119.766 44.588C119.766 44.588 120.114 44.6302 121.175 44.5231C121.655 44.4809 122.148 44.4188 122.669 44.3343C124.609 44.0468 127.741 43.4548 132.669 42.2456C139.913 44.9065 143.519 44.6021 143.519 44.6021C148.406 44.6021 151.559 43.0574 151.736 37.7271C151.736 37.6876 151.876 34.6349 151.915 32.6674V32.6618C151.92 32.3433 151.926 32.0501 151.926 31.8049H151.915V29.6232C151.915 26.7706 152.554 24.9948 153.599 23.9265C154.589 22.9174 156.099 22.3226 157.844 22.3226H159.051C160.796 22.3226 162.307 22.9174 163.296 23.9265C164.341 24.9976 164.983 26.4831 164.983 29.3357V43.8523C164.983 44.2723 165.317 44.6133 165.727 44.6133H171.794C172.205 44.6133 172.538 44.2723 172.538 43.8523V29.9304C172.538 24.9948 171.491 22.083 169.63 19.8252C167.309 16.967 163.878 15.1827 159.054 15.1827Z" fill="white"/>
    <path d="M221.972 13.1725C221.972 13.1725 220.321 13.9025 218.08 14.2126C216.73 14.3986 212.992 14.9032 210.911 15.1654C207.035 15.6558 204.632 17.2146 201.972 20.4562C198.771 17.0455 194.923 15.1823 191.004 15.1823C188.983 15.1823 186.968 15.6643 185.02 16.6142C178.164 19.9572 175.289 26.5841 177.128 34.7896L177.153 34.8854C177.194 35.0179 178.167 38.1833 179.997 39.9986C180.907 40.9006 182.925 42.7751 184.774 44.4776C184.358 44.83 183.939 45.1964 183.523 45.5798C181.852 47.1103 180.193 48.8918 178.864 51.0087C175.945 55.6625 175.347 60.9984 177.222 65.6493C178.986 70.024 182.743 73.1585 187.531 74.2465C188.721 74.5171 189.94 74.6553 191.153 74.6553C197.743 74.6553 203.455 70.6724 205.362 64.7473C207.234 58.9322 205.955 53.4526 201.558 48.4662C199.152 45.7348 196.288 43.5813 193.515 41.4954C192.98 41.0923 192.445 40.6892 191.913 40.2833C189.537 38.468 187.296 36.7542 185.703 34.3667C183.925 31.6974 183.892 28.5488 185.615 25.9415C187.247 23.4722 189.896 22.4857 192.605 22.9395C200.072 24.1882 201.972 32.061 201.972 32.061C203.907 24.1234 211.25 22.9169 211.25 22.9169C218.78 21.1665 220.68 16.758 221.173 15.0892C221.396 14.331 222.152 13.1584 221.972 13.1725ZM189.592 50.2786C190.067 49.9009 190.571 49.5288 191.109 49.1624C193.937 51.1835 196.514 53.3398 197.815 56.5786C199.138 59.8709 198.292 63.5945 195.759 65.6352C194.515 66.6359 192.922 67.1884 191.271 67.1884C189.692 67.1884 188.159 66.7035 186.842 65.7874C183.978 63.7946 183.101 59.7666 184.755 56.2093C185.791 53.9853 187.354 52.0601 189.592 50.2786Z" fill="white"/>
    <path d="M103.336 14.1308C99.488 14.2041 96.4201 15.1992 94.0358 16.5042C89.7799 18.8297 87.1612 23.4046 87.1612 28.3374V32.7544C87.1392 34.4372 87.2329 34.5697 87.1227 35.7366C87.1227 35.7366 86.8498 38.0142 82.8943 36.6189C80.5293 35.7282 80.4714 34.7332 80.3859 33.3576C80.2839 31.6945 80.3804 31.0321 80.3694 29.8313C80.3694 29.7806 80.3777 29.7299 80.3777 29.6791C80.3777 19.9121 71.2373 12.3297 61.2287 15.3824C56.6888 16.7664 53.1027 20.4364 51.7465 25.0761C48.7586 35.3138 56.1761 44.658 65.7272 44.658C69.5614 44.658 71.7831 44.1478 75.4602 40.8499C77.4503 43.0372 80.9455 44.6044 86.078 44.6044C86.078 44.6044 88.0626 44.6721 90.1988 44.1788C94.7304 43.1331 94.5347 38.8091 94.5485 37.7887C94.5485 37.7436 94.6036 34.2314 94.6119 32.3344C94.6119 32.3231 94.6119 32.309 94.6119 32.2977V29.1125C94.6119 26.2346 96.3402 23.6301 98.9808 22.6322C100.254 22.1502 101.737 21.7951 103.344 21.75C103.59 21.7443 103.785 21.5442 103.785 21.2933V14.5847C103.791 14.3338 103.584 14.1252 103.336 14.1308ZM73.481 30.4909C73.1199 34.2201 70.1733 37.2362 66.5266 37.6055C61.598 38.1072 57.4909 33.9073 57.9788 28.8673C58.3399 25.1381 61.2893 22.122 64.9333 21.7528C69.8591 21.2539 73.9662 25.451 73.481 30.4909Z" fill="white"/>
</svg>
</template>

<script>
export default {
  name: 'starling-logo',
};
</script>
